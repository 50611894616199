import React, { useEffect, useState } from 'react'
import ArrowRightImg from '../assets/arrow-right.svg';
import BulbImg from '../assets/bulb.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function Income() {
    const navigate = useNavigate();

    const [yes, setYes] = useState(false);
    const [formData, setFormData] = useState();

    useEffect(() => {
        let incom = localStorage.getItem('income');
        if(incom) {
            setFormData(incom);
            setYes(true);
        }
    }, []);

    const handleFormOpt = (e) => {
        e.preventDefault();
        setYes(true);
        setFormData(e.target.value);
    }

    const moveToNext = () => {
        localStorage.setItem('income', formData);
        navigate('/mortgage');
    }

    return (
        <main>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <p className="navbar-brand fs-6 mb-0">Part 1: Coverage needs</p>
                </div>
            </nav>

            <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 40}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-start mb-4'>What is your annual pre-tax income?</h2>
                        </div>
                        <div className='row forms-one mt-3'>
                            <div className='col-12 mb-4'>
                                {/* <div className={`border border-dark rounded-0 py-3 px-5 text-start `} onClick={(e) => handleFormOpt(e, 'yes')}>
                                    <p className='opt-box__para mb-0 fs-6'>Yes</p>
                                </div> */}
                                <input 
                                    className={` w-100 bg-green border border-dark rounded-0 py-3 px-4 text-start`} 
                                    type="text" 
                                    name="income"
                                    placeholder='$0'
                                    value={formData}
                                    onChange={(e) => handleFormOpt(e)}
                                />
                            </div>

                            <div class="col-12">
                                <div className="bg-blue d-flex flex-row">
                                    <img className='me-3' src={BulbImg} alt="" /> 
                                    <p className='text-dark mb-0 text-start'>
                                        <small>Getting an estimate of your income helps us understand your family planning needs.</small>
                                    </p>
                                </div> 
                            </div>
                            
                            <div className='col-12 mt-4'>
                                <div className="d-grid">
                                    <button 
                                        onClick={moveToNext}
                                        className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                        disabled={yes ? false : true}
                                    >
                                        <span>Next</span>
                                        <img className='pe-3' src={ArrowRightImg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
