import React, { useEffect, useState } from 'react'
import ProtectImg from '../assets/protect.svg';
import MortgageImg from '../assets/mortgage.svg';
import PlanImg from '../assets/plan.svg';
import PeaceImg from '../assets/peace.svg';
import ArrowRightImg from '../assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function FinanciallyForm() {
    const navigate = useNavigate();

    const [partner, setPartner] = useState('');
    const [partnerClick, setPartnerClick] = useState(false);
    const [children, setChildren] = useState('');
    const [childrenClick, setChildrenClick] = useState(false);
    const [parent, setParent] = useState('');
    const [parentClick, setParentClick] = useState(false);
    const [other, setOther] = useState('');
    const [otherClick, setOtherClick] = useState(false);

    useEffect(() => {
        let pro = localStorage.getItem('protections');
        if(pro) {
            let prot = JSON.parse(pro);
            arrSave = prot;
            prot.map((val, ind) => {
                if(val == 'partner') {
                    setPartner(val);
                    setPartnerClick(true);
                }
                if(val == 'children') {
                    setChildren(val);
                    setChildrenClick(true);
                }
                if(val == 'parent') {
                    setParent(val);
                    setParentClick(true);
                }
                if(val == 'other') {
                    setOther(val);
                    setOtherClick(true);
                }
            });
        }
    }, []);

    const handleFormOpt = (e, value) => {
        e.preventDefault();
        if(value == 'partner') {
            setPartner(value);
            setPartnerClick((a) => !a);
        } else if(value == 'children') {
            setChildren(value);
            setChildrenClick((a) => !a);
        } else if(value == 'parent') {
            setParent(value);
            setParentClick((a) => !a);
        } else if(value == 'other') {
            setOther(value);
            setOtherClick((a) => !a);
        }
        saveToLocalDB(value);
    }

    const saveToLocalDB = (val) => {
        arrSave.push(val);
    }

    const moveToNext = () => {
        let uniqueChars = [...new Set(arrSave)];
        if(!partnerClick) {
            uniqueChars = removeVal(uniqueChars, 'partner');
        } 
        if(!childrenClick) {
            uniqueChars = removeVal(uniqueChars, 'children');
        }
        if(!parentClick) {
            uniqueChars = removeVal(uniqueChars, 'parent');
        }
        if(!otherClick) {
            uniqueChars = removeVal(uniqueChars, 'other');
        }
        localStorage.setItem('protections', JSON.stringify(uniqueChars));
        navigate('/howItWorks');
    }

    const removeVal = (uniqueChars, val) => {
        let unique = uniqueChars;
        let latestArr = unique.filter((value, index) => value !== val);
        return latestArr;
    }

    return (
        <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 80}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='text-start mb-4'>Who depends on you financially?</h2>
                        <p className='text-muted text-start'>
                            This helps us customize your plan.
                        </p>
                    </div>
                    <div className='row forms-one mt-3'>
                        <div className='col-12 col-md-6 mb-4'>
                            <div className={`opt-box text-center ${partnerClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'partner')}>
                                <p className='opt-box__para'>I want to protect my loved ones</p>
                                <img src={ProtectImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <div className={`opt-box text-center ${childrenClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'children')}>
                                <p className='opt-box__para'>I want to pay off my mortgage</p>
                                <img src={MortgageImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <div className={`opt-box text-center ${parentClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'parent')}>
                                <p className='opt-box__para'>I want to prepare for my family's future</p>
                                <img src={PlanImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 mb-4'>
                            <div className={`opt-box text-center ${otherClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'other')}>
                                <p className='opt-box__para'>I'm looking for peace of mind</p>
                                <img src={PeaceImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 '>
                            <div className="d-grid">
                                <button 
                                    onClick={moveToNext}
                                    className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                    disabled={partnerClick || childrenClick || parentClick || otherClick ? false : true}
                                >
                                    <span>Next</span>
                                    <img className='pe-3' src={ArrowRightImg} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
