import React from 'react'

export default function Header() {
  return (
    <section>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center py-3 px-5 border-dark border-bottom">
                    <h1 className="fs-3">
                        <a className='text-decoration-none text-dark' href="https://alphaandomegainsuranceagency.genicpixels.com/">
                            INSURANCE
                        </a>
                    </h1>
                    <div className='d-flex flex-column justify-content-center'>
                        <span className='text-muted text-uppercase fw-bold'><small>Call us for help</small></span>
                        <a className='text-decoration-none text-muted' href="tel:4159150665">(415) 915-0665</a>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  )
}
