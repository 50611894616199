import React, { useState } from 'react'
import ArrowRightImg from '../assets/arrow-right.svg';
import WillImg from '../assets/will.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function WillsEducational() {
    const navigate = useNavigate();

    const moveToNext = () => {
        navigate('/income');
    }

    return (
        <main>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <p className="navbar-brand fs-6 mb-0">Part 1: Coverage needs</p>
                </div>
            </nav>

            <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 40}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-center mb-4'>Nice! <br/> You're ahead of the pack.</h2>
                            <p className='text-center mt-4'>
                                Less than half of American adults have an estate plan or will.
                            </p>
                            <p className='text-center'>
                                FYI, Ethos policyholders have access to a range of estate planning tools, all for free if you ever need it.
                            </p>

                            <img className='text-center' src={WillImg} alt="" />
                        </div>
                        <div className='row forms-one mt-3'>
                            
                            <div className='col-12 mt-4'>
                                <div className="d-grid">
                                    <button 
                                        onClick={moveToNext}
                                        className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                    >
                                        <span>Next</span>
                                        <img className='pe-3' src={ArrowRightImg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
