import React, { useEffect, useState } from 'react'
import ProtectImg from '../assets/protect.svg';
import MortgageImg from '../assets/mortgage.svg';
import PlanImg from '../assets/plan.svg';
import PeaceImg from '../assets/peace.svg';
import ArrowRightImg from '../assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function Intent() {
    const navigate = useNavigate();

    const [today, setToday] = useState(false);
    const [week, setWeek] = useState(false);
    const [month, setMonth] = useState(false);
    const [sure, setSure] = useState(false);
    const [formData, setFormData] = useState();

    useEffect(() => {
        let inten = localStorage.getItem('intent');
        setFormData(inten);
        if(inten) {
            if(inten == 'today') {
                setToday(true);
            }
            if(inten == 'week') {
                setWeek(true);
            }
            if(inten == 'month') {
                setMonth(true);
            }
            if(inten == 'sure') {
                setSure(true);
            }
        }
    }, []);

    const handleFormOpt = (e, value) => {
        e.preventDefault();
        if(value == 'today') {
            setToday((a) => !a);
            setWeek(false);
            setMonth(false);
            setSure(false);
        } else if(value == 'week') {
            setWeek((a) => !a);
            setToday(false);
            setMonth(false);
            setSure(false);
        } else if(value == 'month') {
            setMonth((a) => !a);
            setToday(false);
            setWeek(false);
            setSure(false);
        } else if(value == 'sure') {
            setSure((a) => !a);
            setToday(false);
            setWeek(false);
            setMonth(false);
        }
        setFormData(value);
    }

    const moveToNext = () => {
        localStorage.setItem('intent', formData);
        navigate('/gender');
    }

    return (
        <main>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <p className="navbar-brand fs-6 mb-0">Part 1: Coverage needs</p>
                </div>
            </nav>

            <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 40}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-start mb-4'>When would you like to be covered by life insurance?</h2>
                        </div>
                        <div className='row forms-one mt-3'>
                            <div className='col-12 mb-4'>
                                <div className={`bg-green border border-dark rounded-0 py-3 px-5 text-start ${today ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'today')}>
                                    <p className='opt-box__para mb-0 fs-6'>I'm ready today</p>
                                </div>
                            </div>
                            <div className='col-12 mb-4'>
                                <div className={`bg-green border border-dark rounded-0 py-3 px-5 text-start ${week ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'week')}>
                                    <p className='opt-box__para mb-0 fs-6'>Within a week</p>
                                </div>
                            </div>
                            <div className='col-12 mb-4'>
                                <div className={`bg-green border border-dark rounded-0 py-3 px-5 text-start ${month ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'month')}>
                                    <p className='opt-box__para mb-0 fs-6'>In a few months</p>
                                </div>
                            </div>
                            <div className='col-12 mb-4'>
                                <div className={`bg-green border border-dark rounded-0 py-3 px-5 text-start ${sure ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'sure')}>
                                    <p className='opt-box__para mb-0 fs-6'>I'm not sure</p>
                                </div>
                            </div>
                            <div className='col-12 mt-4'>
                                <div className="d-grid">
                                    <button 
                                        onClick={moveToNext}
                                        className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                        disabled={today || week || month || sure ? false : true}
                                    >
                                        <span>Next</span>
                                        <img className='pe-3' src={ArrowRightImg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
