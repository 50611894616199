import React, { useState } from 'react'
import ArrowRightImg from '../assets/arrow-right.svg';
import PartnerImg from '../assets/partner.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function Recommendation() {
    const navigate = useNavigate();

    const moveToNext = () => {
        navigate('/confirmEligibility');
    }

    return (
        <main>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <p className="navbar-brand fs-6 mb-0">Part 1: Coverage needs</p>
                </div>
            </nav>

            <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 40}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-center mb-4'>Term Life insurance sounds like a good fit!</h2>
                            <p className='text-center mt-4'>
                                A Term Life policy helps cover your financial obligations, like debt and children's tuition, for a set period of time (term) at a fixed monthly price.
                            </p>
                            <p className='text-center'>
                                <small>PROTECTION FOR</small>
                            </p>
                            
                        </div>
                        <div className='row forms-one mt-3'>
                            <div className='d-flex justify-content-center'>
                                <div className='col-12 col-md-4 mb-5'>
                                    <div className={`opt-box text-center`}>
                                        <p className='opt-box__para'>Partner</p>
                                        <img src={PartnerImg} alt="Protect Image" />
                                    </div>
                                </div>

                            </div>
                            
                            <div className='col-12 mt-4'>
                                <div className="d-grid">
                                    <button 
                                        onClick={moveToNext}
                                        className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                    >
                                        <span>Next</span>
                                        <img className='pe-3' src={ArrowRightImg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
