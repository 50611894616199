import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import './App.css';
import BirthDate from './components/birthDate';
import Children from './components/children';
import ConfirmEligibility from './components/confirmEligibility';
import FinanciallyForm from './components/financiallyForm';
import Form from './components/form';
import Gender from './components/gender';
import Header from './components/header';
import Health from './components/health';
import HowItWorks from './components/howItWorks';
import Income from './components/income';
import Intent from './components/intent';
import Mortgage from './components/mortgage';
import Nicotine from './components/nicotine';
import Recommendation from './components/recommendation';
import Wills from './components/wills';
import WillsEducational from './components/willsEducational';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Form />} />
        <Route path="/protections" element={<FinanciallyForm />} />
        <Route path="/howItWorks" element={<HowItWorks />} />
        <Route path="/intent" element={<Intent />} />
        <Route path="/gender" element={<Gender />} />
        <Route path="/birthDate" element={<BirthDate />} />
        <Route path="/health" element={<Health />} />
        <Route path="/nicotine" element={<Nicotine />} />
        <Route path="/children" element={<Children />} />
        <Route path="/wills" element={<Wills />} />
        <Route path="/willsEducational" element={<WillsEducational />} />
        <Route path="/income" element={<Income />} />
        <Route path="/mortgage" element={<Mortgage />} />
        <Route path="/recommendation" element={<Recommendation />} />
        <Route path="/confirmEligibility" element={<ConfirmEligibility />} />
      </Routes>
    </div>
  );
}

export default App;
