import React, { useEffect, useState } from 'react'
import ArrowRightImg from '../assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function Wills() {
    const navigate = useNavigate();

    const [yes, setYes] = useState(false);
    const [no, setNo] = useState(false);
    const [sure, setSure] = useState(false);
    const [formData, setFormData] = useState();

    useEffect(() => {
        let wil = localStorage.getItem('wills');
        setFormData(wil);
        if(wil) {
            if(wil == 'yes') {
                setYes(true);
            }
            if(wil == 'no') {
                setNo(true);
            }
            if(wil == 'sure') {
                setSure(true);
            }
        }
    }, []);

    const handleFormOpt = (e, value) => {
        e.preventDefault();
        if(value == 'yes') {
            setYes((a) => !a);
            setNo(false);
            setSure(false);
        } else if(value == 'no') {
            setNo((a) => !a);
            setYes(false);
            setSure(false);
        } else if(value == 'sure') {
            setSure((a) => !a);
            setNo(false);
            setYes(false);
        }
        setFormData(value);
    }

    const moveToNext = () => {
        localStorage.setItem('wills', formData);
        navigate('/willsEducational');
    }

    return (
        <main>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <p className="navbar-brand fs-6 mb-0">Part 1: Coverage needs</p>
                </div>
            </nav>

            <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 40}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-start mb-4'>Do you have an Estate Plan or Will in place?</h2>
                        </div>
                        <div className='row forms-one mt-3'>
                            <div className='col-12 mb-4'>
                                <div className={`bg-green border border-dark rounded-0 py-3 px-5 text-start ${yes ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'yes')}>
                                    <p className='opt-box__para mb-0 fs-6'>Yes</p>
                                </div>
                            </div>
                            <div className='col-12 mb-4'>
                                <div className={`bg-green border border-dark rounded-0 py-3 px-5 text-start ${no ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'no')}>
                                    <p className='opt-box__para mb-0 fs-6'>No</p>
                                </div>
                            </div>
                            <div className='col-12 mb-4'>
                                <div className={`bg-green border border-dark rounded-0 py-3 px-5 text-start ${sure ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'sure')}>
                                    <p className='opt-box__para mb-0 fs-6'>I'm not sure</p>
                                </div>
                            </div>
                            <div className='col-12 mt-4'>
                                <div className="d-grid">
                                    <button 
                                        onClick={moveToNext}
                                        className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                        disabled={yes || no || sure  ? false : true}
                                    >
                                        <span>Next</span>
                                        <img className='pe-3' src={ArrowRightImg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
