import React, { useEffect, useState } from 'react'
import ArrowRightImg from '../assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function BirthDate() {
    const navigate = useNavigate();

    const [bdate, setBDate] = useState('');

    useEffect(() => {
        let birth = localStorage.getItem('birthDate');
        if(birth) {
            setBDate(birth);
        }
    }, []);

    const handleFormOpt = (e) => {
        e.preventDefault();
        setBDate(e.target.value);
    }

    const moveToNext = () => {
        localStorage.setItem('birthDate', bdate);
        navigate('/health');
    }

    return (
        <main>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <p className="navbar-brand fs-6 mb-0">Part 1: Coverage needs</p>
                </div>
            </nav>

            <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 40}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-start mb-4'>What's your birthdate?</h2>
                        </div>
                        <div className='row forms-one mt-3'>
                            <div className='col-12 mb-4'>
                                <input 
                                    className={` w-100 bg-green border border-dark rounded-0 py-3 px-4 text-start`} 
                                    type="date" 
                                    name="date" 
                                    min="1995-01-01"
                                    value={bdate}
                                    onChange={(e) => handleFormOpt(e)}
                                />
                            </div>
                            <div className='col-12 mt-4'>
                                <div className="d-grid">
                                    <button 
                                        onClick={moveToNext}
                                        className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                        disabled={bdate ? false : true}
                                    >
                                        <span>Next</span>
                                        <img className='pe-3' src={ArrowRightImg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
