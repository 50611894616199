import React, { useState } from 'react'
import ProtectImg from '../assets/protect.svg';
import MortgageImg from '../assets/mortgage.svg';
import PlanImg from '../assets/plan.svg';
import PeaceImg from '../assets/peace.svg';
import ArrowRightImg from '../assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function HowItWorks() {
    const navigate = useNavigate();

    const moveToNext = () => {
        navigate('/intent');
    }

    return (
        <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 80}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='text-center mb-4'>Great! We'll get your rates in as little as 10 minutes.</h2>
                        <p className='text-muted text-center d-flex flex-row align-items-center justify-content-center'>
                            <p class="SectionCoverPage__greenBlob__kro6l"></p>
                            <strong>850</strong> policies approved today
                        </p>
                    </div>

                    <div className='row'>
                        <div className="SectionCoverPage__flagContainer__HQZkA mt-5 text-start"><div className="SectionCoverPage__flag__NXC5E"><div className="TypeBase-module__Eyebrow2__eJ2mS TypeBase-module__Theinhardt__OQedf TypeBase-module__Medium500__CztgD TypeBase-module__White__LSZCH TypeBase-module__AllCaps___6ATo">How it works</div></div></div>
                        <div className="SectionCoverPage__svgContainer__T3IUR">
                            <div className="SectionCoverPage__blurbs__ySHGf">
                                <div className="SectionCoverPage__blurbLine__Gaj1S">
                                <div className="SectionCoverPage__circle__jge7E SectionCoverPage__numeral__MQsrj">
                                    <div className="TypeBase-module__TitleSmall2__b6SEQ TypeBase-module__Theinhardt__OQedf TypeBase-module__Medium500__CztgD">1</div>
                                </div>
                                <div className="SectionCoverPage__blurbCopy__mV2Tj">
                                    <span className="SectionCoverPage__copy__AN17F">Understand your coverage needs</span>
                                </div>
                                </div>
                                <div className="SectionCoverPage__nonCaptionContainer__E6BaI">
                                <div className="SectionCoverPage__line__LtTMe"></div>
                                <div className="SectionCoverPage__lineSpacer__EsNX7"></div>
                                <div className="SectionCoverPage__line__LtTMe"></div>
                                </div>
                                <div className="SectionCoverPage__blurbLine__Gaj1S">
                                <div className="SectionCoverPage__circle__jge7E SectionCoverPage__numeral__MQsrj">
                                    <div className="TypeBase-module__TitleSmall2__b6SEQ TypeBase-module__Theinhardt__OQedf TypeBase-module__Medium500__CztgD">2</div>
                                </div>
                                <div className="SectionCoverPage__blurbCopy__mV2Tj">
                                    <span className="SectionCoverPage__copy__AN17F">Confirm your eligibility</span>
                                </div>
                                </div>
                                <div className="SectionCoverPage__nonCaptionContainer__E6BaI">
                                <div className="SectionCoverPage__line__LtTMe"></div>
                                <div className="SectionCoverPage__lineSpacer__EsNX7"></div>
                                <div className="SectionCoverPage__line__LtTMe"></div>
                                </div>
                                <div className="SectionCoverPage__blurbLine__Gaj1S">
                                <div className="SectionCoverPage__circle__jge7E SectionCoverPage__numeral__MQsrj">
                                    <div className="TypeBase-module__TitleSmall2__b6SEQ TypeBase-module__Theinhardt__OQedf TypeBase-module__Medium500__CztgD">3</div>
                                </div>
                                <div className="SectionCoverPage__blurbCopy__mV2Tj">
                                    <span className="SectionCoverPage__copy__AN17F">Provide health &amp; lifestyle info</span>
                                </div>
                                </div>
                                <div className="SectionCoverPage__nonCaptionContainer__E6BaI">
                                <div className="SectionCoverPage__line__LtTMe"></div>
                                <div className="SectionCoverPage__lineSpacer__EsNX7"></div>
                                <div className="SectionCoverPage__line__LtTMe"></div>
                                </div>
                                <div className="SectionCoverPage__blurbLine__Gaj1S">
                                <div className="SectionCoverPage__circle__jge7E SectionCoverPage__numeral__MQsrj">
                                    <div className="TypeBase-module__TitleSmall2__b6SEQ TypeBase-module__Theinhardt__OQedf TypeBase-module__Medium500__CztgD">4</div>
                                </div>
                                <div className="SectionCoverPage__blurbCopy__mV2Tj">
                                    <span className="SectionCoverPage__copy__AN17F">Get your coverage options</span>
                                </div>
                                </div>
                                <div className="SectionCoverPage__nonCaptionContainer__E6BaI"></div>
                            </div>
                        </div>
                    </div>

                    <div className='row forms-one mt-3'>
                        <div className='col-12'>
                            <div className="d-grid">
                                <button 
                                    onClick={moveToNext}
                                    className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                >
                                    <span>Next: Coverage needs</span>
                                    <img className='pe-3' src={ArrowRightImg} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
