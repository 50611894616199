import React, { useEffect, useState } from 'react'
import ProtectImg from '../assets/protect.svg';
import MortgageImg from '../assets/mortgage.svg';
import PlanImg from '../assets/plan.svg';
import PeaceImg from '../assets/peace.svg';
import SureImg from '../assets/sure.svg';
import ArrowRightImg from '../assets/arrow-right.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function Form() {
    const navigate = useNavigate();

    const [protest, setProtest] = useState('');
    const [protestClick, setProtestClick] = useState(false);
    const [mortgage, setMortgage] = useState('');
    const [mortgageClick, setMortgageClick] = useState(false);
    const [future, setFuture] = useState('');
    const [futureClick, setFutureClick] = useState(false);
    const [peace, setPeace] = useState('');
    const [peaceClick, setPeaceClick] = useState(false);
    const [final, setFinal] = useState('');
    const [finalClick, setFinalClick] = useState(false);
    const [sure, setSure] = useState('');
    const [sureClick, setSureClick] = useState(false);

    useEffect(() => {
        let goals = localStorage.getItem('goals');
        if(goals) {
            let gol = JSON.parse(goals);
            arrSave = gol;
            gol.map((val, ind) => {
                if(val == 'goal-protect') {
                    setProtest(val);
                    setProtestClick(true);
                }
                if(val == 'goal-mortgage') {
                    setMortgage(val);
                    setMortgageClick(true);
                }
                if(val == 'goal-familyFuture') {
                    setFuture(val);
                    setFutureClick(true);
                }
                if(val == 'goal-peace') {
                    setPeace(val);
                    setPeaceClick(true);
                }
                if(val == 'goal-unsure') {
                    setSure(val);
                    setSureClick(true);
                }
            });
        }
    }, []);
    
    const handleFormOpt = (e, value) => {
        e.preventDefault();
        if(value == 'goal-protect') {
            setProtest(value);
            setProtestClick((a) => !a);
        } else if(value == 'goal-mortgage') {
            setMortgage(value);
            setMortgageClick((a) => !a);
        } else if(value == 'goal-familyFuture') {
            setFuture(value);
            setFutureClick((a) => !a);
        } else if(value == 'goal-peace') {
            setPeace(value);
            setPeaceClick((a) => !a);
        } else if(value == 'goal-final') {
            setFinal(value);
            setFinalClick((a) => !a);
        } else if(value == 'goal-unsure') {
            setSure(value);
            setSureClick((a) => !a);
        }
        saveToLocalDB(value);
    }

    const saveToLocalDB = (val) => {
        arrSave.push(val);
    }

    const moveToNext = () => {
        let uniqueChars = [...new Set(arrSave)];
        if(!protestClick) {
            uniqueChars = removeVal(uniqueChars, 'goal-protect');
        } 
        if(!mortgageClick) {
            uniqueChars = removeVal(uniqueChars, 'goal-mortgage');
        }
        if(!futureClick) {
            uniqueChars = removeVal(uniqueChars, 'goal-familyFuture');
        }
        if(!peaceClick) {
            uniqueChars = removeVal(uniqueChars, 'goal-peace');
        }
        if(!finalClick) {
            uniqueChars = removeVal(uniqueChars, 'goal-final');
        }
        if(!sureClick) {
            uniqueChars = removeVal(uniqueChars, 'goal-unsure');
        }
        localStorage.setItem('goals', JSON.stringify(uniqueChars));
        navigate('/protections');
    }

    const removeVal = (uniqueChars, val) => {
        let unique = uniqueChars;
        let latestArr = unique.filter((value, index) => value !== val);
        return latestArr;
    }


    return (
        <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 80}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className='text-start'>Let's get started! What are your goals for life insurance?</h2>
                    </div>
                    <div className='row forms-one mt-3'>
                        <div className='col-12 col-md-4 mb-4'>
                            <div className={`opt-box text-center ${protestClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'goal-protect')}>
                                <p className='opt-box__para'>I want to protect my loved ones</p>
                                <img src={ProtectImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <div className={`opt-box text-center ${mortgageClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'goal-mortgage')}>
                                <p className='opt-box__para'>I want to pay off my mortgage</p>
                                <img src={MortgageImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <div className={`opt-box text-center ${futureClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'goal-familyFuture')}>
                                <p className='opt-box__para'>I want to prepare for my family's future</p>
                                <img src={PlanImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <div className={`opt-box text-center ${peaceClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'goal-peace')}>
                                <p className='opt-box__para'>I'm looking for peace of mind</p>
                                <img src={PeaceImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <div className={`opt-box text-center ${finalClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'goal-final')}>
                                <p className='opt-box__para'>I want to cover my final expenses</p>
                                <img src={ProtectImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 col-md-4 mb-4'>
                            <div className={`opt-box text-center ${sureClick ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'goal-unsure')}>
                                <p className='opt-box__para'>I'm not sure</p>
                                <img src={SureImg} alt="Protect Image" />
                            </div>
                        </div>
                        <div className='col-12 '>
                            <div className="d-grid">
                                <button 
                                    onClick={moveToNext}
                                    className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                    disabled={protestClick || mortgageClick || futureClick || peaceClick || finalClick || sureClick ? false : true}
                                >
                                    <span>Next</span>
                                    <img className='pe-3' src={ArrowRightImg} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
