import React, { useEffect, useState } from 'react'
import ProtectImg from '../assets/protect.svg';
import MortgageImg from '../assets/mortgage.svg';
import PlanImg from '../assets/plan.svg';
import PeaceImg from '../assets/peace.svg';
import ArrowRightImg from '../assets/arrow-right.svg';
import BulbImg from '../assets/bulb.svg';
import { useNavigate } from 'react-router-dom';

let arrSave = [];
export default function Children() {
    const navigate = useNavigate();

    const [zero, setZero] = useState(false);
    const [one, setOne] = useState(false);
    const [two, setTwo] = useState(false);
    const [three, setThree] = useState(false);
    const [four, setFour] = useState(false);
    const [formData, setFormData] = useState();

    useEffect(() => {
        let child = localStorage.getItem('children');
        setFormData(child);
        if(child) {
            if(child == 'zero') {
                setZero(true);
            }
            if(child == 'one') {
                setOne(true);
            }
            if(child == 'two') {
                setTwo(true);
            }
            if(child == 'three') {
                setThree(true);
            }
            if(child == 'four') {
                setFour(true);
            }
        }
    }, []);

    const handleFormOpt = (e, value) => {
        e.preventDefault();
        if(value == 'zero') {
            setZero((a) => !a);
            setOne(false);
            setTwo(false);
            setThree(false);
            setFour(false);
        } else if(value == 'one') {
            setOne((a) => !a);
            setZero(false);
            setTwo(false);
            setThree(false);
            setFour(false);
        } else if(value == 'two') {
            setTwo((a) => !a);
            setZero(false);
            setOne(false);
            setThree(false);
            setFour(false);
        } else if(value == 'three') {
            setThree((a) => !a);
            setZero(false);
            setOne(false);
            setTwo(false);
        } else if(value == 'four') {
            setFour((a) => !a);
            setThree(false);
            setZero(false);
            setOne(false);
            setTwo(false);
        }
        setFormData(value);
    }

    const moveToNext = () => {
        localStorage.setItem('children', formData);
        navigate('/wills');
    }

    return (
        <main>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <p className="navbar-brand fs-6 mb-0">Part 1: Coverage needs</p>
                </div>
            </nav>

            <section style={{ margin: 'auto', maxWidth: 500, paddingBottom: 40, paddingTop: 40}}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='text-start mb-4'>How many children do you have under 18?</h2>
                        </div>
                        <div className='row forms-one mt-3'>
                            <div className='col-12 col-md-2 mb-4 flex-fill'>
                                <div className={`bg-green border border-dark rounded-0 py-3  text-center ${zero ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'zero')}>
                                    <p className='opt-box__para mb-0 fs-6'>0</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-2 mb-4 flex-fill'>
                                <div className={`bg-green border border-dark rounded-0 py-3  text-center ${one ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'one')}>
                                    <p className='opt-box__para mb-0 fs-6'>1</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-2 mb-4 flex-fill'>
                                <div className={`bg-green border border-dark rounded-0 py-3  text-center ${two ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'two')}>
                                    <p className='opt-box__para mb-0 fs-6'>2</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-2 mb-4 flex-fill'>
                                <div className={`bg-green border border-dark rounded-0 py-3  text-center ${three ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'three')}>
                                    <p className='opt-box__para mb-0 fs-6'>3</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-2 mb-4 flex-fill'>
                                <div className={`bg-green border border-dark rounded-0 py-3  text-center ${four ? 'active': ''}`} onClick={(e) => handleFormOpt(e, 'four')}>
                                    <p className='opt-box__para mb-0 fs-6'>4+</p>
                                </div>
                            </div>

                            <div class="col-12">
                                <div className="bg-blue d-flex flex-row">
                                    <img className='me-3' src={BulbImg} alt="" /> 
                                    <p className='text-dark mb-0 text-start'>
                                        <small>This helps us estimate your family coverage needs.</small>
                                    </p>
                                </div> 
                            </div>

                            <div className='col-12 mt-4'>
                                <div className="d-grid">
                                    <button 
                                        onClick={moveToNext}
                                        className='btn btn-dark btn-block rounded-0 d-flex justify-content-between align-items-center py-3'
                                        disabled={zero || one || two || three || four ? false : true}
                                    >
                                        <span>Next</span>
                                        <img className='pe-3' src={ArrowRightImg} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
